<template lang="pug">
  //- content
  v-container#login.fill-height.justify-center
    //- Snackbar
    v-snackbar(v-model='snackbar' color='success' bottom center)
      | Recovery email sent, please check your inbox for instructions on how to recover your password.
      v-btn(dark='' text='' @click='snackbar = false')
        | Close
    //- content
    .float-banner(v-if="userType === 'contributor'")
    v-row.mt-1
      v-col(cols="12").text-center
        v-img.mx-auto.mb-5(src="@/assets/images/lupa.png" contain aspect-radio="1.4" height="auto" width="100px")
        h1.display-2.font-weight-bold.custom-main-title(v-if="recovery === false" style="color: white")
          span(v-if="userType === 'Buyer'") Login customer
          span(v-else) Login {{userType}}
        h1.display-2.font-weight-bold.custom-main-title(v-else style="color: white")
          | Password Recovery
    //- content
    v-row(justify="center")
      v-slide-y-transition(appear="")
        //- card login
        v-card.pa-3.pa-md-5.mx-auto(light raised shaped max-width="100%" width="400")
          v-card-title(v-if="userType === 'Buyer'")
            v-spacer
            v-icon(aria-label="Close" @click="$emit('close')")
              | mdi-close
          v-card-text.text-center.px-0
            //- error message
            v-snackbar(multiline top color='error' timeout='3500' dense :outlined='!isMobile' v-model="isError")
              | {{errMsg}}
            //- input email
            v-text-field.rounded(v-if="!recovery" v-model="account.email" color="secondary" label="Email..." solo dense filled prepend-inner-icon="mdi-email" type="email")
            v-text-field.rounded(v-else v-model="account.email" color="secondary" label="Email" solo dense filled prepend-inner-icon="mdi-email" type="email" :append-icon="'mdi-login'" @click:append="toggleRecoveryMode")
            div
              v-text-field.rounded(v-if="!recovery" v-model="account.password" color="secondary" solo dense label="Password..." prepend-inner-icon="mdi-lock-outline" :append-icon="'mdi-backup-restore'" @click:append="toggleRecoveryMode" type="password")
            //- footer
            v-row
              v-col(cols="12").text-center
                pages-btn.v-btn--text.white--text(color="primary" small :disabled="isLoading" @click="login" v-if="!recovery")
                  | Login
                  v-icon(right="" dark="") mdi-arrow-right
                pages-btn.v-btn--text.white--text(color="primary" small :disabled="isLoading" @click="recoveryPass" v-if="recovery")
                  | Recovery
              v-col(cols="12" v-if="!recovery" ).text-center
                v-btn(text large color='success' @click="toggleRecoveryMode").text-capitalize Forgot password?
              v-col(cols="12" v-if="recovery" ).text-center
                v-btn(text large color='success' @click="toggleRecoveryMode").text-capitalize Log In
              //- Google login
              v-col(cols="12" v-show ="!recovery").text-center
                v-btn.mx-2(fab small color="secondary" @click="loginProvider('google')")
                  v-icon(dark="") mdi-google
                v-btn.mx-2(fab small color="secondary" @click="loginProvider('facebook')")
                  v-icon(dark="") mdi-facebook
                #firebaseui-auth-container.d-none
    v-row(style="z-index:999")
      v-col(cols="12").text-center
        b.mr-1(style="color: white") Don`t have an account ?
        v-btn.m-0(small color='primary' v-if='userType === "contributor"' href="#/pages/register" )
          | Sign Up
        v-btn.m-0(small color='primary' v-else @click="$emit('switchDialog')" )
          | Sign Up
</template>

<script>
  /* eslint-disable */
  import 'firebaseui/dist/firebaseui.css'
  import firebase from 'firebase'
  const firebaseui = require('firebaseui')
  export default {
    name: 'PagesLogin',

    components: {
      PagesBtn: () => import('./component/Btn'),
    },

    data: () => ({
      isError: false,
      recovery: false,
      snackbar: false,
      direction: 'bottom center',
      errMsg: '',
      account: {
        email: '',
        password: '',
      },
    }),

    props: {
      userType: {
        type: String,
        default: function () {
          return 'contributor'
        },
      },
    },

    computed: {
      isMobile(){
        return window.innerWidth < 1024
      },
      isAuth () {
        return !!this.$store.state.users.user
      },
      isLoading () {
        return this.$store.state.users.isLoading
      },
      parsedDirection () {
        return this.direction.split(' ')
      },
      user () {
        return this.$store.state.idle.user
      },
    },
    created () {
      const vm = this
      firebase.auth().onAuthStateChanged(function (currentUser) {
        try {
          if (currentUser) {
            currentUser.getIdTokenResult().then((idTokenResult) => {
              vm.$store.dispatch('users/setCookie', idTokenResult.token)
              // const path = vm.userType === 'contributor' ? '/vuejs-pwa/#/contributor' : '/vuejs-pwa/#/'
                if(currentUser.displayName === 'collaboratorCreatedInAdmin'){
                  /* vm.$router.replace(path).catch(err => {
                    console.log(err)
                  }) */
                  vm.$emit('close')
                }else{
                  const { email, uid } = currentUser
                  vm.$store.commit('users/SET_USER', { email, uid })
                  /* vm.$router.replace(path).catch(err => {
                    console.log(err)
                  }) */
                  vm.$emit('close')
                }
            })
          }
        } catch (error) {
          console.log(error)
        }
      })
    },
    mounted () {
      const vm = this
      const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: vm.userType === 'contributor' ? '#/contributor' : '#/',
        signInOptions: [
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
      }
      let ui = firebaseui.auth.AuthUI.getInstance()
      if (!ui) {
        ui = new firebaseui.auth.AuthUI(firebase.auth())
      }
      ui.start('#firebaseui-auth-container', uiConfig)
    },

    methods: {
      toggleRecoveryMode () {
        this.recovery = !this.recovery
      },
      loginProvider (provider) {
        switch (provider) {
          case 'facebook':
            document.getElementsByClassName('firebaseui-idp-facebook')[0].click()
            break
          case 'google':
            document.getElementsByClassName('firebaseui-idp-google')[0].click()
            break
        }
      },
      login (e) {
        const vm = this
        e.preventDefault()
        vm.$store.dispatch('users/login', vm.account)
          .then(function (res) {
            if (vm.userType === 'contributor') vm.$router.replace('/contributor')
            else vm.$emit('close')
          })
          .catch(error => {
            console.log(error)
            switch(error.code){
              case('auth/user-not-found'):
                vm.errMsg = 'No account has been set up under this email'
              break
              case('auth/wrong-password'):
                vm.errMsg = 'Invalid password entered, please try again or click on the link below to reset it'
              break
              case('auth/invalid-email'):
                vm.errMsg = 'Incorrect email address'
              break
            }
            vm.isError = true
            setTimeout(() => {
              vm.isError = false
            }, 5000)
          })
      },
      recoveryPass (e) {
        const vm = this
        e.preventDefault()
        this.$store.dispatch('users/forgotPass', this.account)
          .then(() => {
            vm.recovery = false
            vm.snackbar = true
          })
          .catch(error => {
            vm.isError = true
            vm.errMsg = error.code
            setTimeout(() => {
              this.isError = false
            }, 5000)
          })
      },
    },
  }
</script>
<style lang="scss">
// esto debe ir en un componente aparte del sass
  $primary: #B2262D;
  $secondary: #337F9D;
  $secondary_2: #123E69;
  $secondary_3: #46A4BB;
  $neutral: #808184;

/*.login-alert{*/
  /*position:fixed;*/
  /*top: 0;*/
  /*right: 0;*/
  /*left: 0;*/
  /*@media(min-width : 768px){*/
    /*position:relative;*/
    /*top: unset;*/
    /*right: unset;*/
    /*left: unset;*/
  /*}*/
/*}*/
.v-card--material__heading{
  display: none;
}
.custom-material-card{
  border-radius: 20px;
  position: relative;
  z-index: 100;
}
.custom-main-title{
  z-index: 200;
  position: relative;
}
.rounded{
  border-radius: 20px;
  .v-input__slot{
    background-color: #bada55;
  }
}
.v-application ul{
  padding: 0;
}
.firebaseui-idp-list{
  display: flex;
  flex-direction: column;
  .firebaseui-list-item .firebaseui-idp-button{
    border-radius: 5px;
  }
}
.float-banner{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba($secondary, .6);
    top: 0;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{
      box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.2);
}
@media (max-width: 576px) {
  .custom-material-card{
    max-width: 80% !important;
  }
}
@media (max-width: 375px) {
  .firebaseui-idp-text{
    // display: none !important;
  }
}
</style>
